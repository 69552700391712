.existing-pricing__main {
  .existing-pricing--block {
    .pricing-block-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      .pricing-block-text {
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value-color;
      }
      .upload-new-price{
        cursor: pointer;
        width: auto;
        gap: 10px;
        display: flex;
        justify-content: space-between;
      }
      .add-new-price {
        cursor: pointer;
        .add-icon {
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          margin-right: 0.5rem;
          line-height: 1.875;
          text-align: center;
          color: #e02f17;
        }
        .add-model-text {
          font-style: normal;
          font-weight: bold;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #e12f17;
        }
      }
    }
    .pricing-list-table {
      .edit-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: #3c7cdd;
        cursor: pointer;
      }
      .delete-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: #be210b;
        cursor: pointer;
      }
      
      .eye-icon {
        cursor: pointer;
        color: #ffffff;
      }
    }
  }
  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.file-upload-popup {
  display: flex;
  flex-direction: column;
  background-color: #1b2a38;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.file-drop-area {
  border: 2px dashed #ccc;
  padding: 150px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.file-drop-area p {
  margin: 0;
}

.small-text {
  font-size: 12px;
  color: #666;
}

// pricing-form-css
// .pricing-form-page__main {
//   .pricing-form-header__block {
//     padding: 1rem 0;
//     margin-bottom: 1rem;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     border-bottom: 2px solid #2e3841;
//     .pricing-header-name {
//       font-size: 1.25rem;
//     }
//     .close-btn {
//       padding: 0.1rem;
//       border-radius: 10px;
//       cursor: pointer;
//       &:hover {
//         background-color: black;
//       }
//     }
//   }
//   .pricing-form-body__block {
//     padding: 1rem 0;
//     .pricing-form--block {
//       .combo-input__block {
//         margin-top: 3rem;
//         .combo--label {
//           font-size: 1rem;
//           margin-bottom: 0.7rem;
//           color: $input-label-color;
//           font-weight: 500;
//           margin-right: 1rem;
//         }
//       }
//       .schedule-block__field-inputs {
//         .field--btn {
//           border-color: transparent;
//           background-color: transparent;
//           color: $primary-btn-color;
//           font-size: 1rem;
//           padding: 0;
//           font-weight: 600;
//           display: flex;
//           align-items: center;
//           &:focus,
//           &:active {
//             box-shadow: none;
//           }
//           .plus--icon {
//             margin-right: 0.2rem;
//           }
//         }
//       }
//       .time-picker__input-block {
//         .time--input-box-block {
//           .form-input-group {
//             margin-bottom: 0;
//           }
//           .input-box--divider {
//             height: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//           }
//         }
//       }
//       .form-input-group {
//         .pre-text-input {
//           padding-left: 2.5rem !important;
//         }
//       }
//       .form-btn--block {
//         text-align: right;
//         .pricing-form-btn {
//           padding: 0.8rem 1.7rem;
//           font-weight: 500;
//         }
//         .cancel-btn {
//           margin-right: 1rem;
//           background-color: transparent;
//           border-color: transparent;
//           color: $primary-btn-color;
//           &:hover {
//             background-color: #36303a;
//           }
//           &:focus {
//             box-shadow: none;
//           }
//         }
//       }
//     }
//   }
// }

// pricing-form-css

.pricing-form-page-main {
  .pricing-form-card {
    .pricing-form__block {
      padding-bottom: 2rem;
      .combo-input__block {
        margin-top: 3rem;
        .combo--label {
          font-size: 1rem;
          margin-bottom: 0.7rem;
          color: $input-label-color;
          font-weight: 500;
          margin-right: 1rem;
        }
      }
      .schedule-block__field-inputs {
        .schedule-box {
          background: $primary-bg-color;
          border: 1.023px solid #3b4a56;
          box-sizing: border-box;
          border-radius: 20.453px;
          padding: 0.5rem;
          margin-bottom: 1rem;
        }
        .field--btn {
          border-color: transparent;
          background-color: transparent;
          color: $primary-btn-color;
          font-size: 1rem;
          padding: 0;
          font-weight: 600;
          display: flex;
          align-items: center;
          &:focus,
          &:active {
            box-shadow: none;
          }
          .plus--icon {
            margin-right: 0.2rem;
          }
        }
      }
      .time-picker__input-block {
        .time--input-box-block {
          .form-input-group {
            margin-bottom: 0;
          }
          .input-box--divider {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .form-input-group {
        .pre-text-input {
          padding-left: 2.5rem !important;
        }
      }
      .form-btn--block {
        text-align: right;
        .pricing-form-btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
        .create-btn {
          margin-right: 1rem;
        }
        .cancel-btn {
          background-color: transparent;
          border-color: transparent;
          color: $primary-btn-color;
          background-color: #36303a;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .pricing-form__inner {
        padding: 1rem 0;
      }
    }
  }
}

//modal-css

.tariff-log-model_main_content {
  display: flex;
  padding: 1.5rem;
  .title_name {
    flex-grow: 1;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .table-download-btn {
    padding: 0.2rem;
    border-radius: 10px;
    cursor: pointer;
  }
  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  .table-close-btn {
    padding: 0.1rem;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: black;
    }
  }
}
.tariff-log-table {
  padding: 1.5rem 2rem 1.5rem 2rem;
  .user-log {
    background: $primary-bg-color;
    box-sizing: border-box;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    .user-info {
      cursor: pointer;
      margin-right: 0.5rem;
      display: flex;
      width: 20em;
      svg {
        font-size: 1.5rem;
      }
      .user-header {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value-color;
        margin-left: 1rem;
        padding: 0px;
      }
      .user-data {
        font-style: normal;
        font-weight: 300;
        font-size: 0.9rem;
        line-height: 1.25rem;
        color: $input-value-color;
        margin-left: 1rem;
        padding: 0px;
      }
    }
  }
  .table-responsive {
    @media screen and (max-width: 1440px) {
      max-width: none !important;
    }
  }
}
.loader--block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tariff-log-empty-data-block {
  text-align: center;
  font-size: 0.9rem;
  color: #8d9dac;
}
