.form-check-input {
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  border: 2px solid gray;
  box-sizing: border-box;
  &.form-check-input:checked {
    background-color: #be210b;
    border-color: unset;
    border: 2px solid gray;
  }
  &.form-check-input:focus {
    outline: 0;
    box-shadow: unset;
    border: 2px solid gray;
  }
  &.form-check-input:disabled {
    background-color: gray;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 30px;
  color: #ffffff;
}
.toggle-input {
  display: none;
}

.toggle-label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 10px;
}

.toggle-button {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.toggle-button.checked {
  transform: translateX(20px);
}

.toggle-input:checked + .toggle-label {
  background-color: #6abf69;
}
/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Default background color */
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0f9b72; /* Background color when switched on */
}

input:disabled + .slider {
  background-color: rgb(228, 223, 223);
  cursor: not-allowed
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

input:disabled + .slider:before {
  cursor: not-allowed;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.toggle-input {
  display: none;
}

.toggle-label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 10px;
}

.toggle-button {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.toggle-button.checked {
  transform: translateX(20px);
}

.toggle-input:checked + .toggle-label {
  background-color: #6abf69;
}
/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Default background color */
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6edc5f; /* Background color when switched on */
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
