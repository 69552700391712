//customer Detail
.customerDetail-page__main {
  .page--info {
    display: flex;
    justify-content: space-between;
    .page--info--inner {
      display: flex;
      align-items: center;
      .customer-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .customer-name {
          font-style: normal;
          font-weight: 500;
          font-size: 1.625rem;
          line-height: 2.063rem;
          color: $input-value-color;
          margin-bottom: 0.7rem;
        }
        .customer-role {
          .customer_roles-select__box {
            margin: 0;
            width: 14rem;
            .react-select {
              .react-select__control {
                border: 1px solid $text-color-light;
                border-radius: 4px !important;
                .react-select__value-container {
                  padding: 0.1rem 0.5rem;
                }
              }
            }
          }
        }
        .customer-info {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value-color;
        }
      }
      .customer-detail-action__block {
        display: inline-flex;
        .customer-detail--block {
          .customer--detail {
            color: #f5604c;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            font-weight: 500;
            font-size: 0.9rem;
            .dot--icon {
              display: inline-flex;
              height: 1.5rem;
              width: 1.5rem;
            }
          }
        }
      }
    }
    .customerDetail-dropdown_btn {
      .dropdown-icon > svg {
        color: $react-pagination-detail-color;
        font-size: 2rem;
      }
    }
  }
  .customerDetail-table {
    margin-top: 1rem;
    .customerDetail-table-heading {
      margin: 1rem;
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 1.4rem;
      border-bottom: 1px solid $amenity-form-border-color;
    }
    .customerDetail-list-table {
      .item-icon {
        padding-right: 1rem;
      }
    }
  }
}

// customerProfile-tab
.customerProfile-details__main {
  .customerProfile-details-inner {
    .customerProfile--content {
      margin-bottom: 1rem;
      .customerProfile-details--wrapper {
        margin-bottom: 1.5rem;
      }
    }
  }
}

//customerBooking-tab

.customerBooking-table {
  margin-top: 1rem;
  .customerBooking-table-heading-row {
    border-bottom: 1px solid $amenity-form-border-color;
    .customerBooking-table-heading {
      margin: 1rem;
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 1.4rem;
    }
    .customerBooking-filter-block {
      display: flex;
      padding: 0.9rem;
      .customerBooking-filter-icon {
        display: flex;
        align-items: center;
        .customerBooking-filter-label {
          padding-left: 0.5rem;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
      .customerBooking-arrow__icon {
        color: $primary-btn-color;
        margin-left: auto;
      }
    }
    .customerBooking-filter-dropdown {
      min-width: 12rem;
      margin-right: 1rem;
      @media screen and (max-width: 1440px) {
        min-width: 10rem;
      }
      @media screen and (max-width: 1024px) {
        min-width: 12rem;
      }
      @media screen and (max-width: 768px) {
        min-width: 12rem;
      }
    }
    .customerBooking-search-button {
      border: none;
      cursor: pointer;
      border-radius: 5px;
      padding: 0.9rem 1.5rem;
      font-size: 0.9rem;
    }
    .customerBooking-search-excel-block {
      padding-left: 0.5rem;
      // padding-top: 0.5rem;
    }
    .customerBooking-excel-icon {
      cursor: pointer;
      margin-top: 0.5rem;
      margin-left: 1.5rem;
      @media screen and (max-width: 1024px) {
        margin-left: 0rem;
      }
      @media screen and (max-width: 768px) {
        margin-left: 0rem;
      }
      .download-table-xls-button {
        color: $excel-download-button;
        background: transparent;
        border: none;
        svg {
          height: 2rem;
        }
      }
    }
  }
  .customerBooking-list-table {
    .station-link {
      cursor: pointer;
    }
    .station-link:hover {
      text-decoration: underline;
    }
    .item-icon {
      padding-right: 1rem;
    }
  }
}

.scroll-container {
  position: relative;
  width: 100%;
  overflow: hidden; /* Prevent scrolling outside the container */
}

.status-blocks {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  overflow-x: auto;
  gap: 10px;
  scroll-behavior: smooth;
}

.status-blocks::-webkit-scrollbar {
  display: none;
}

.status-blocks {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.status-block {
  flex: 0 0 19%;
  background-color: $primary-bg-color;
  height: 7.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 20px;
  margin: 0px 5px;
  cursor: pointer;
  position: relative;
}

.status-block:hover {
  background-color: $status-block-hover;
}

.breating-area {
  background-color: $page-content-wrapper;
  padding: 1vw;
  border-radius: 20px;
}

.status-info {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
}

.status-info h1 {
  margin: 0px;
  padding: 0px;
  font-size: 20px;
}

.status-info p {
  margin: 0px;
  padding: 0px;
  font-weight: 100;
  font-size: 15px;
  letter-spacing: 0.1px;
}

/* Media Queries for Responsive Design */
@media (min-width: 1200px) and (max-width: 1366px) {
  .status-block {
    margin: 0px 2px;
    height: 7em;
  }
  .status-info p {
    font-size: 0.65em;
    letter-spacing: 0px;
  }
}

@media (min-width: 1001px) and (max-width: 1202px) {
  .status-blocks {
    gap: 10px;
  }
  .status-block {
    padding: 0.7em;
    margin: 0px;
  }
  .status-info p {
    font-size: 13px;
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .status-block {
    width: 48%;
    height: 7.5rem;
    padding: 10px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .status-block {
    width: 48%;
    height: 7.5rem;
    padding: 5px;
    border-radius: 10px;
  }
  .status-block svg {
    height: 20px;
    width: 20px;
  }
  .status-info p {
    letter-spacing: 0px;
  }
  .status-info {
    padding: 5px;
  }
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  // border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-button.left {
  left: 2px;
}

.scroll-button.right {
  right: 2px;
}

.more-button {
  margin-left: 15px;
  border: 1px solid $billing-info--box;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.more-button:hover {
  background-color: $billing-info--box;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $primary-btn-color;
  padding: 1px 5px;
  cursor: pointer;
  border-radius: 0px 5px 0px 0px;
  margin: 1px;
}

.close-button:hover {
  background-color: $primary-btn-color-hover;
}
